import React from 'react'

// Still waiting on assets for png's
import GirlWithFlowers from '../../images/illustrations/girl-with-flowers.png'
import House from "../../images/house.png"

import Bird from '../../images/svgs/bird.svg'
import Bridget from '../../images/svgs/bridget.svg'
import Calculator from '../../images/svgs/calculator.svg'
import Daisy from '../../images/svgs/daisy.svg'
import FaceSmiling from '../../images/svgs/face-smiling.svg'
import Family from '../../images/svgs/family.svg'
import FlowerBed from '../../images/svgs/flowerbed-unfinished.svg'
import Flowers from '../../images/svgs/flowers.svg'
import GiftVoucherTwentyFive from '../../images/svgs/gift-voucher-25.svg'
import GirlAndBoy from '../../images/svgs/girl-and-boy.svg'
import Hearts from '../../images/svgs/hearts.svg'
import HeartsThick from '../../images/svgs/hearts-thick.svg'
import LadySide from '../../images/svgs/lady-side.svg'
import LadyWithSign from '../../images/svgs/lady-with-sign.svg'
import PlantPots from '../../images/svgs/plant-pots.svg'
import Postbox from '../../images/svgs/postbox.svg'
import SmallHouse from '../../images/svgs/small-house.svg'
import SpecialMessage from '../../images/svgs/special-message.svg'
import Sunflower from '../../images/svgs/sunflower.svg'
import SunnyScene from '../../images/svgs/sunny-scene.svg'
import Tulips from '../../images/svgs/tulips.svg'

import Bench from '../../images/svgs/bench.svg'
import BenchAndSwim from '../../images/svgs/bench-and-swim.svg'
import Bike from '../../images/svgs/bike.svg'
import Camper from '../../images/svgs/camper.svg'
import DiamondGirl from '../../images/svgs/diamond-girl.svg'
import FlowerGirl from '../../images/svgs/flower-girl.svg'
import Football from '../../images/svgs/football.svg'
import Group1 from '../../images/svgs/group-1.svg'
import Group2 from '../../images/svgs/group-2.svg'
import Group3 from '../../images/svgs/group-3.svg'
import Pancake from '../../images/svgs/pancake.svg'
import Shopping from '../../images/svgs/shopping.svg'
import Skater from '../../images/svgs/skater.svg'
import TheScienceLipo from '../../images/svgs/the-science-lipo.svg'
import UpsideDown from '../../images/svgs/upside-down.svg'

export default function Illustrations( props ) {

	const getIllustration = ( abbr ) => {
		switch ( abbr ) {
			case 'girlWithFlowers':
			case 'GirlWithFlowers':
				return <img src={ GirlWithFlowers } className={ props.class } alt="" />
			case 'House':
				return <img src={ House } className={ props.class } alt="" />
			case 'Bird':
				return <Bird className={ props.class } />
			case 'Bridget':
				return <Bridget className={ props.class } />
			case 'Calculator':
				return <Calculator className={ props.class } />
			case 'Daisy':
				return <Daisy className={ props.class } />
			case 'FaceSmiling':
				return <FaceSmiling className={ props.class } />
			case 'Family':
				return <Family className={ props.class } />
			case 'FlowerBedUnfinished':
				return <FlowerBed className={ props.class } />
			case 'Flowers':
				return <Flowers className={ props.class } />
			case 'GiftVoucherTwentyFive':
				return <GiftVoucherTwentyFive className={ props.class } />
			case 'girlAndBoy':
				return <GirlAndBoy className={ props.class } />
			case 'Hearts':
				return <Hearts className={ props.class } />
			case 'HeartsThick':
				return <HeartsThick className={ props.class } />
			case 'LadySide':
				return <LadySide className={ props.class } />
			case 'LadyWithSign':
				return <LadyWithSign className={ props.class } />
			case 'PlantPots':
				return <PlantPots className={ props.class } />
			case 'Postbox':
				return <Postbox className={ props.class } />
			case 'SmallHouse':
				return <SmallHouse className={ props.class } />
			case 'SpecialMessage':
				return <SpecialMessage className={ props.class } />
			case 'Sunflower':
				return <Sunflower className={ props.class } />
			case 'SunnyScene':
				return <SunnyScene className={ props.class } />
			case 'Tulips':
				return <Tulips className={ props.class } />
			case 'Bench':
				return <Bench className={ props.class } />
			case 'BenchAndSwim':
				return <BenchAndSwim className={ props.class } />
			case 'Bike':
				return <Bike className={ props.class } />
			case 'Camper':
				return <Camper className={ props.class } />
			case 'DiamondGirl':
				return <DiamondGirl className={ props.class } />
			case 'FlowerGirl':
				return <FlowerGirl className={ props.class } />
			case 'Football':
				return <Football className={ props.class } />
			case 'Group1':
				return <Group1 className={ props.class } />
			case 'Group2':
				return <Group2 className={ props.class } />
			case 'Group3':
				return <Group3 className={ props.class } />
			case 'Pancake':
				return <Pancake className={ props.class } />
			case 'Shopping':
				return <Shopping className={ props.class } />
			case 'Skater':
				return <Skater className={ props.class } />
			case 'TheScienceLipo':
				return <TheScienceLipo className={ props.class } />
			case 'UpsideDown':
				return <UpsideDown className={ props.class } />
			default:
				return <GirlAndBoy className={ props.class } />
		}
	}

	return (
		<>
			{ getIllustration( props.illustration ) }
		</>
	)
}